import Vue from "vue";

import {commonWrapper} from 'Utils/helpers'

import Slider from "Classes/ui/Slider"
import NewsletterForm from "Components/ui/NewsletterForm";

commonWrapper(({store, i18n, data}) => {

    // CROSS SELL
    const crossSellExist = document.getElementById("crossSell");
    if(crossSellExist){
       new Slider({
           el : "#crossSell",
           store
       });
    }

    const signupNewsletterExist = document.getElementById("newsletterForm");
    if(signupNewsletterExist){
        const props = {}
        if (data.sourceNewsletter){
            props.source = data.sourceNewsletter;
        }
       new Vue({
           el: '#newsletterForm',
           store,
           i18n,
           render: function(createElement) {
               return createElement(NewsletterForm, {props});
           },
           components: { NewsletterForm }
       })
    }
})

